import { DatoImage } from '@components/image/DatoImage';
import { HeroImageFragment } from '@graphql/generated';
import React from 'react';

type Props = {
  home: boolean;
  title: string;
  heroText: string;
  heroImage: HeroImageFragment;
};

export const Hero: React.FC<Props> = ({ home, title, heroText, heroImage }) => {
  const prefixClass = home ? 'home' : 'page';
  return (
    <div className={`${prefixClass}-hero`}>
      <div className={`${prefixClass}-hero__body`}>
        <div className={`${prefixClass}-hero__body__inner`}>
          <div className={`${prefixClass}-hero__image`}>
            <DatoImage data={heroImage.responsiveImage} priority={true} />
          </div>
          <div className={`${prefixClass}-hero__heading`}>
            <h1>{title}</h1>
          </div>
          {heroText && (
            <div className={`${prefixClass}-hero__text`}>
              <div dangerouslySetInnerHTML={{ __html: heroText }} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
