import { DatoImage } from '@components/image/DatoImage';
import { BannerFragment } from '@graphql/generated';
import { ExternalLink } from '../ExternalLink';
import { InternalLink } from '../InternalLink';

type Props = {
  block: BannerFragment;
};

export const Banner: React.FC<Props> = ({ block }) => {
  const { id, image, title, text, callToAction, internalLink, cssClass, externalLink } = block;

  return (
    <div className={`cta-block ${cssClass}`} key={id}>
      {image && (
        <div className="cta-block__image">
          <DatoImage data={image!.responsiveImage} />
        </div>
      )}
      <div className="cta-block__content">
        <div className="cta-block__title">{title}</div>
        <div className="cta-block__text">
          <div dangerouslySetInnerHTML={{ __html: text || '' }} />
        </div>
        {externalLink != '' && (
          <div className="cta-block__action">
            <ExternalLink
              title={callToAction || ''}
              externalLink={externalLink || ''}
              className="button--primary"
              target="_blank"
              id={id}
              rel="noopener"
            >
              {' '}
            </ExternalLink>
          </div>
        )}
        <br />
        {internalLink && (
          <div className="cta-block__action">
            <InternalLink
              title={callToAction || ''}
              internalLink={internalLink}
              className="button--primary"
            />
          </div>
        )}
      </div>
    </div>
  );
};
