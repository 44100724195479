import { CategoryListQuery } from '@graphql/generated';
import { subPaths } from '@lib/utils/pathUtils';
import { Locales } from '@model/common';

type Props = {
  categoryList: CategoryListQuery['categoryList'];
  parentSlug?: string;
  baseUrl: string;
  locale: Locales;
};

export const CategoryNav: React.FC<Props> = ({
  categoryList,
  parentSlug = null,
  baseUrl,
  locale,
}) => {
  return (
    <div className="category-menu">
      <div className="category-menu__list">
        {categoryList.map((cat) => {
          const slug = parentSlug ? [parentSlug, cat.slug] : [cat.slug];
          return (
            <div className="category-menu__item " key={cat.id}>
              <a
                href={`${baseUrl}/${subPaths.categories[locale]}/${slug.join('/')}`}
                title={cat.name}
              >
                {cat.name}
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CategoryNav;
