import {
  BannerFragment,
  CategoryContentBlocksAfterFragment,
  CategoryContentBlocksBeforeFragment,
  CategoryQuery,
  GalleryFragment,
  HtmlContentFragment,
  MediaBlockFragment,
  PreviewCategoryRecord,
  SiteQuery,
  SlideshowFragment,
} from '@graphql/generated';
import { Locales } from '@model/common';
import { Products } from '../products/Products';
import { ModularText } from './ModularText';
import { Banner } from './modularBlocks/Banner';
import { Gallery } from './modularBlocks/Gallery';
import { HtmlContent } from './modularBlocks/HtmlContent';
import { Media } from './modularBlocks/Media';
import { Slideshow } from './modularBlocks/Slideshow';

type Props = {
  locale: Locales;
  baseUrl: string;
  block:
    | BannerFragment
    | GalleryFragment
    | SlideshowFragment
    | MediaBlockFragment
    | PreviewCategoryRecord
    | HtmlContentFragment
    | SiteQuery['privacy']['contentBlock'][0]
    | CategoryQuery['category']['description'][0]
    | CategoryContentBlocksBeforeFragment
    | CategoryContentBlocksAfterFragment;
};

export const ModularBlock: React.FC<Props> = ({ locale, baseUrl, block }) => {
  switch (block.__typename) {
    case 'BannerRecord':
      return <Banner block={block} />;
    case 'GalleryRecord':
      return <Gallery block={block} />;
    case 'SlideshowRecord':
      return <Slideshow block={block} />;
    case 'MediaBlockRecord':
      return <Media block={block} />;
    case 'PreviewCategoryRecord':
      return (
        <Products
          cssClass={block.cssClass}
          products={block.products}
          locale={locale}
          baseUrl={baseUrl}
        />
      );
    case 'HtmlContentRecord':
      return <HtmlContent block={block} />;
    case 'TitleBlockRecord':
    case 'TextInlineRecord':
    case 'NewParagraphRecord':
    case 'InternalLinkRecord':
      return <ModularText block={block} />;
    default:
      return null;
  }
};
