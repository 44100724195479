import { DatoImage } from '@components/image/DatoImage';
import { GalleryFragment } from '@graphql/generated';
import { ExternalLink } from '../ExternalLink';
import { InternalLink } from '../InternalLink';

type Props = {
  block: GalleryFragment;
};

export const Gallery: React.FC<Props> = ({ block }) => {
  const { images, linkImages, id, title, cssClass } = block;

  return (
    <div className={`gallery ${cssClass}`} key={id}>
      {title && <div className="gallery__title">{title}</div>}
      <div className="gallery__list">
        {images &&
          images.map((image, index) => {
            return (
              <div className="gallery__item" key={index}>
                <div className="gallery__item__inner">
                  {image && <DatoImage data={image.responsiveImage} className="gallery__image" />}
                </div>
              </div>
            );
          })}
        {linkImages &&
          linkImages.map((linkImage, index) => {
            return (
              <div className="gallery__item" key={index}>
                <div className="gallery__item__inner">
                  {linkImage.externalLink ? (
                    <ExternalLink
                      id={id}
                      className="slideshow__link"
                      target="_blank"
                      rel="noopener"
                      externalLink={linkImage.externalLink}
                    >
                      <DatoImage
                        data={linkImage.image?.responsiveImage}
                        className="gallery__image"
                      />
                    </ExternalLink>
                  ) : linkImage.internalLink ? (
                    <InternalLink
                      className="slideshow__link"
                      internalLink={linkImage.internalLink}
                      showTitle={false}
                    >
                      <DatoImage
                        data={linkImage.image?.responsiveImage}
                        className="gallery__image"
                      />
                    </InternalLink>
                  ) : (
                    <DatoImage data={linkImage.image?.responsiveImage} className="gallery__image" />
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
