'use client';

import { ModularBlock } from '@components/contents/ModularBlock';
import { WrapBlock, groupByTypes } from '@components/contents/WrapBlocks';
import { Hero } from '@components/layout/Hero';
import {
  CategoryContentBlocksAfterFragment,
  CategoryContentBlocksBeforeFragment,
  CategoryQuery,
  ProductFragment,
} from '@graphql/generated';
import { Locales } from '@model/common';
import { Fragment, useEffect, useState } from 'react';
import { ModularText } from '../contents/ModularText';
import { Products } from '../products/Products';
import CategoryNav from './CategoryNav';

type GroupedBlocksBefore = {
  inParagraph: boolean;
  blocks: CategoryContentBlocksBeforeFragment[];
}[];
type GroupedBlocksAfter = {
  inParagraph: boolean;
  blocks: CategoryContentBlocksAfterFragment[];
}[];

type Props = {
  data: { category: CategoryQuery['category']; products: ProductFragment[]; categoryList: any[] };
  locale: Locales;
  baseUrl: string;
};

export const Category: React.FC<Props> = ({ data, locale, baseUrl }) => {
  const { category, categoryList, products } = data;
  const { title = '', heroImage = null, heroText = '' } = category;

  const groupedBlocksBefore = groupByTypes(category.contentBlocksBefore) as GroupedBlocksBefore;
  const groupedBlocksAfter = groupByTypes(category.contentBlocksAfter) as GroupedBlocksAfter;

  function modularBlockCategory(groupedBlocks: GroupedBlocksBefore | GroupedBlocksAfter) {
    return (
      <div className="category-modular-block">
        {groupedBlocks &&
          groupedBlocks.map((item, index) => {
            return (
              <Fragment key={`wrap-${index}`}>
                <WrapBlock grouped={item.inParagraph}>
                  {item?.blocks?.map((block, index) => {
                    return (
                      <ModularBlock key={index} block={block} locale={locale} baseUrl={baseUrl} />
                    );
                  })}
                </WrapBlock>
              </Fragment>
            );
          })}
      </div>
    );
  }

  const [initGtm, setInitGtm] = useState(false);
  useEffect(() => {
    if (!initGtm && products && category) {
      setInitGtm(true);
    }
  }, [initGtm, products, category]);

  return (
    <>
      {heroImage && <Hero title={title} heroImage={heroImage} heroText={heroText} home={true} />}
      <div>
        {categoryList && (
          <>
            <CategoryNav categoryList={categoryList} baseUrl={baseUrl} locale={locale} />
            {modularBlockCategory(groupedBlocksBefore)}
          </>
        )}

        {category?.children?.length > 0 && (
          <CategoryNav
            categoryList={category.children}
            parentSlug={category.slug}
            baseUrl={baseUrl}
            locale={locale}
          />
        )}
        {category && (
          <div className="category-intro">
            {category.title && !heroImage && (
              <h1 className="category-intro__title">{category.title}</h1>
            )}
            {category.description && (
              <div className="category-intro__description">
                {category.description.map((block, i) => {
                  return (
                    <div className="text_block" key={'cat_descr_' + i}>
                      <ModularText block={block} />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}

        {products && <Products products={products} locale={locale} baseUrl={baseUrl} />}

        {modularBlockCategory(groupedBlocksAfter)}
      </div>
    </>
  );
};

export default Category;
