import { HtmlContentFragment } from '@graphql/generated';

type Props = {
  block: HtmlContentFragment;
};

export const HtmlContent: React.FC<Props> = ({ block }) => {
  const { id, title, body, cssClass } = block;
  return (
    <div className={`text-content ${cssClass}`} key={id}>
      <h2 className="text-content__title" dangerouslySetInnerHTML={{ __html: title || '' }}></h2>
      <div className="text-content__text" dangerouslySetInnerHTML={{ __html: body || '' }} />
    </div>
  );
};
