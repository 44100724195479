import { PageContentBlocksFragment, ProductContentBlocksFragment } from '@graphql/generated';
import { ReactNode } from 'react';

const paragraph_types = ['TextInlineRecord', 'InternalLinkRecord'];

export const groupByTypes = (
  contentBlocks: ProductContentBlocksFragment[] | PageContentBlocksFragment[]
) => {
  let inParagraph = false;

  const result = contentBlocks?.reduce(
    (acc, block) => {
      let isParagraph = paragraph_types.includes(block.__typename || '');
      if ((!isParagraph && inParagraph != null) || isParagraph != inParagraph) {
        acc.groups.push({ inParagraph: isParagraph, blocks: [] });
      }
      let lastElement = acc.groups.length - 1;
      acc.groups[lastElement].blocks.push(block);
      inParagraph = isParagraph ? true : false;
      return acc;
    },
    { groups: [] as any }
  );
  return result ? result.groups : null;
};

type Props = { grouped: boolean; children: ReactNode[] };

export const WrapBlock: React.FC<Props> = ({ grouped, children }) => {
  return <>{grouped ? <div className="text_block">{children}</div> : children}</>;
};
